// Generated by Framer (8c7926c)

import { addFonts, cx, CycleVariantState, Floating, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOverlayState, useVariantState, withCSS, withFX } from "framer";
import { AnimatePresence, LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const serializationHash = "framer-PRNSf"

const variantClassNames = {xo3Gd0cik: "framer-v-1ccljfy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition1, x: 0, y: 0}

const animation1 = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition1, x: 0, y: 0}

const animation2 = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Overlay = ({ children, blockDocumentScrolling, enabled = true, }) => {
const [visible, setVisible] = useOverlayState({ blockDocumentScrolling })
return children({hide: () => setVisible(false), show: () => setVisible(true), toggle: () => setVisible(!visible), visible: enabled && visible})
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "xo3Gd0cik", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEntert682wp = ({overlay, paginationInfo}) => activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
overlay.show()
})

const ref1 = React.useRef<HTMLElement>(null)

const ref2 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Overlay blockDocumentScrolling={false}>{overlay => (<><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ccljfy", className, classNames)} data-framer-name={"Variant 1"} data-highlight id={`${layoutId}-1ccljfy`} layoutDependency={layoutDependency} layoutId={"xo3Gd0cik"} onMouseEnter={onMouseEntert682wp({overlay})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><AnimatePresence>{overlay.visible && (<Floating alignment={"center"} anchorRef={ref ?? ref1} className={cx(serializationHash, classNames, ...sharedStyleClassNames)} collisionDetection collisionDetectionPadding={20} data-framer-portal-id={`${layoutId}-1ccljfy`} offsetX={0} offsetY={10} onDismiss={overlay.hide} placement={"bottom"} portalSelector={"#overlay"} safeArea zIndex={11}><MotionDivWithFX __perspectiveFX={false} __smartComponentFX __targetOpacity={1} animate={animation1} className={"framer-sc288j"} exit={animation} initial={animation2} layoutDependency={layoutDependency} layoutId={"TZoP6DNLv"} ref={ref2} role={"dialog"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: "0px 10px 20px 0px rgba(0,0,0,0.05)"}}/></Floating>)}</AnimatePresence></motion.div></Transition></>)}</Overlay></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PRNSf.framer-7hrrmk, .framer-PRNSf .framer-7hrrmk { display: block; }", ".framer-PRNSf.framer-1ccljfy { height: 80px; position: relative; width: 200px; }", ".framer-PRNSf .framer-sc288j { height: 150px; overflow: hidden; position: relative; width: 200px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 80
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameroLgyF4SMv: React.ComponentType<Props> = withCSS(Component, css, "framer-PRNSf") as typeof Component;
export default FrameroLgyF4SMv;

FrameroLgyF4SMv.displayName = "cursor";

FrameroLgyF4SMv.defaultProps = {height: 80, width: 200};

addFonts(FrameroLgyF4SMv, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})